<template>
  <v-card>
    <v-card-title>
      <span class="headline">Horarios de atención {{ medico.nombre }}</span>
    </v-card-title>

    <v-card-text>
      <ValidationObserver ref="horarios" v-slot="{ invalid, validated, passes }">
        <v-form @submit.prevent="passes(add)">
          <v-row align="stretch" justify="space-between">
            <v-col>
              <ValidationProvider vid="diaDeSemana" name="Día de Semana" rules="required" v-slot="{ errors, valid }">
                <v-select
                  v-model="horario.diaDeSemana"
                  :items="diasDeSemana"
                  item-value="id"
                  item-text="descripcion"
                  outlined
                  dense
                  return-object
                  label="Día de Semana"
                  required
                  :error-messages="errors"
                  :success="valid"
                ></v-select>
              </ValidationProvider>
            </v-col>

            <v-col>
              <ValidationProvider vid="consultorio_id" name="Consultorio" rules="required" v-slot="{ errors, valid }">
                <v-select
                  v-model="horario.consultorio"
                  :items="consultorios"
                  item-value="id"
                  item-text="descripcion"
                  outlined
                  dense
                  return-object
                  label="Consultorio"
                  required
                  :error-messages="errors"
                  :success="valid"
                ></v-select>
              </ValidationProvider>
            </v-col>

            <v-col>
              <ValidationProvider vid="inicio" name="Abre a la(s)" rules="required"
                v-slot="{ errors, valid }">
                <v-text-field
                  v-model="horario.inicio"
                  type="time"
                  label="Abre a la(s)"
                  outlined
                  dense
                  required
                  :error-messages="errors"
                  :success="valid"
                ></v-text-field>
              </ValidationProvider>
            </v-col>

            <v-col>
              <ValidationProvider vid="fin" name="Cierra a la(s)"
                rules="required|timecompare:@inicio"
                v-slot="{ errors, valid }">
                <v-text-field
                  v-model="horario.fin"
                  type="time"
                  label="Cierra a la(s)"
                  outlined
                  dense
                  required
                  :error-messages="errors"
                  :success="valid"
                ></v-text-field>
              </ValidationProvider>
            </v-col>

            <v-col>
              <v-btn block type="submit"
                @click.prevent="passes(add)"
                color="primary"
              >
                Agregar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </ValidationObserver>

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Día de Semana</th>
              <th class="text-left">Horario</th>
              <th class="text-left">Consultorio</th>
              <th class="text-right"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in horarios" :key="item.id">
              <td class="text-left">{{ item.diaDeSemana_descripcion }}</td>
              <td class="text-left">{{ item.horario }}</td>
              <td class="text-left">{{ item.consultorio_descripcion }}</td>
              <td class="text-right">
                <v-icon
                  @click="remove(index)"
                >
                  mdi-close
                </v-icon>
              </td>
            </tr>
            <tr v-show="horarios.length === 0">
              <td colspan="3">No hay horarios de atención registrados.</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="close" :disabled="saving">Cerrar</v-btn>
      <v-btn @click="save" color="primary"
        dark :loading="saving">Guardar</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import {
  ValidationObserver,
  ValidationProvider,
  extend
} from "vee-validate"
//import _ from 'lodash'

extend('timecompare', {
  params: ['target'],
  validate(value, { target }) {
    return value > target;
  },
  message: 'El horario de cierre tiene que ser mayor que el de apertura'
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },

  name: 'HorariosForm',

  props: {
    active: Boolean,
    medico: Object,
    consultorios: Array,
  },

  data: () => ({
    saving: false,
    loading: false,
    diasDeSemana: [
      {value: 1, descripcion: 'Lunes'},
      {value: 2, descripcion: 'Martes'},
      {value: 3, descripcion: 'Miércoles'},
      {value: 4, descripcion: 'Jueves'},
      {value: 5, descripcion: 'Viernes'},
      // TODO: {value: 6, descripcion: 'Sábado'},
      // TODO: {value: 0, descripcion: 'Domingo'},
    ],
    horario: {
      diaDeSemana: null,
      inicio: null,
      fin: null,
      consultorio: null,
    },
    horarios: [],
  }),

  watch: {
    active: {
      immediate: true,
      handler (val) {
        if (val) {
          this.fetch ()
        } else {
          this.horarios = []
        }
      }
    }
  },

  methods: {
    resetForm () {
      this.$refs.horarios.reset()
    },

    close () {
      this.resetForm()
      this.$emit('cancel')
    },

    add () {
      let horario = {
        id: this.horario.id,
        medico_id: this.medico.id,
        consultorio_id: this.horario.consultorio.id,
        consultorio_descripcion: this.horario.consultorio.descripcion,
        diaDeSemana: this.horario.diaDeSemana.value,
        diaDeSemana_descripcion: this.horario.diaDeSemana.descripcion,
        inicio: this.horario.inicio,
        fin: this.horario.fin,
      }

      horario.horario = `${horario.inicio} - ${horario.fin}`

      this.horarios.push(horario)

      this.horarios.sort(function (a, b) {
        if (a.diaDeSemana > b.diaDeSemana) return 1

        if (a.diaDeSemana < b.diaDeSemana) return -1

        if (a.inicio > b.inicio) return 1

        if (a.inicio < b.inicio) return -1

        return 0
      });
    },

    remove (index) {
      this.horarios.splice(index, 1)
    },

    save () {
      this.saving = true

      this.$http.put('medicos/'+this.medico.id+'/horarios', {
        horarios: this.horarios,
      })
      .then((response) => {
        this.$eventHub.$emit('snackbar-message', response.data.message, 'success')
        this.fetch()
      })
      .catch((error) => {
        this.$eventHub.$emit(
          'snackbar-message',
          error?.response?.data?.message || 'No se pudo procesar su solicitud',
          'error'
        )
      })
      .finally(() => { this.saving = false })
    },

    fetch () {
      this.loading = true

      this.$http.get('medicos/'+this.medico.id+'/horarios')
      .then((response) => {
        this.horarios = response.data
      })
      .catch((error) => { this.$eventHub.$emit('http-error', error) })
      .finally(() => { this.loading = false })
    },
  },
}
</script>

<style scoped></style>
