var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Horarios de atención "+_vm._s(_vm.medico.nombre))])]),_c('v-card-text',[_c('ValidationObserver',{ref:"horarios",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var passes = ref.passes;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return passes(_vm.add)}}},[_c('v-row',{attrs:{"align":"stretch","justify":"space-between"}},[_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"diaDeSemana","name":"Día de Semana","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{attrs:{"items":_vm.diasDeSemana,"item-value":"id","item-text":"descripcion","outlined":"","dense":"","return-object":"","label":"Día de Semana","required":"","error-messages":errors,"success":valid},model:{value:(_vm.horario.diaDeSemana),callback:function ($$v) {_vm.$set(_vm.horario, "diaDeSemana", $$v)},expression:"horario.diaDeSemana"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"consultorio_id","name":"Consultorio","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-select',{attrs:{"items":_vm.consultorios,"item-value":"id","item-text":"descripcion","outlined":"","dense":"","return-object":"","label":"Consultorio","required":"","error-messages":errors,"success":valid},model:{value:(_vm.horario.consultorio),callback:function ($$v) {_vm.$set(_vm.horario, "consultorio", $$v)},expression:"horario.consultorio"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"inicio","name":"Abre a la(s)","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"type":"time","label":"Abre a la(s)","outlined":"","dense":"","required":"","error-messages":errors,"success":valid},model:{value:(_vm.horario.inicio),callback:function ($$v) {_vm.$set(_vm.horario, "inicio", $$v)},expression:"horario.inicio"}})]}}],null,true)})],1),_c('v-col',[_c('ValidationProvider',{attrs:{"vid":"fin","name":"Cierra a la(s)","rules":"required|timecompare:@inicio"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('v-text-field',{attrs:{"type":"time","label":"Cierra a la(s)","outlined":"","dense":"","required":"","error-messages":errors,"success":valid},model:{value:(_vm.horario.fin),callback:function ($$v) {_vm.$set(_vm.horario, "fin", $$v)},expression:"horario.fin"}})]}}],null,true)})],1),_c('v-col',[_c('v-btn',{attrs:{"block":"","type":"submit","color":"primary"},on:{"click":function($event){$event.preventDefault();return passes(_vm.add)}}},[_vm._v(" Agregar ")])],1)],1)],1)]}}])}),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Día de Semana")]),_c('th',{staticClass:"text-left"},[_vm._v("Horario")]),_c('th',{staticClass:"text-left"},[_vm._v("Consultorio")]),_c('th',{staticClass:"text-right"})])]),_c('tbody',[_vm._l((_vm.horarios),function(item,index){return _c('tr',{key:item.id},[_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(item.diaDeSemana_descripcion))]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(item.horario))]),_c('td',{staticClass:"text-left"},[_vm._v(_vm._s(item.consultorio_descripcion))]),_c('td',{staticClass:"text-right"},[_c('v-icon',{on:{"click":function($event){return _vm.remove(index)}}},[_vm._v(" mdi-close ")])],1)])}),_c('tr',{directives:[{name:"show",rawName:"v-show",value:(_vm.horarios.length === 0),expression:"horarios.length === 0"}]},[_c('td',{attrs:{"colspan":"3"}},[_vm._v("No hay horarios de atención registrados.")])])],2)]},proxy:true}])})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","disabled":_vm.saving},on:{"click":_vm.close}},[_vm._v("Cerrar")]),_c('v-btn',{attrs:{"color":"primary","dark":"","loading":_vm.saving},on:{"click":_vm.save}},[_vm._v("Guardar")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }